import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "flex"
    }}>{`Flex`}</h1>
    <p>{`If your element is a `}<inlineCode parentName="p">{`flex`}</inlineCode>{` type element, with its own rules or using the `}<inlineCode parentName="p">{`display-flex`}</inlineCode>{` class, the CSS has the `}<inlineCode parentName="p">{`align-items`}</inlineCode>{` and `}<inlineCode parentName="p">{`justify-content`}</inlineCode>{` attribute classes for alignment to Parent Node or `}<inlineCode parentName="p">{`align-self`}</inlineCode>{` and `}<inlineCode parentName="p">{`justify-self`}</inlineCode>{` to Child Node`}</p>
    <h2 {...{
      "id": "align-items"
    }}>{`Align items`}</h2>
    <div className='grid-box display-flex align-items-center mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex align-items-flex-start mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex align-items-flex-end mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex align-items-baseline mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex align-items-stretch mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`
<div class="display-flex align-items-center">...</div>
<div class="display-flex align-items-flex-start">...</div>
<div class="display-flex align-items-flex-end">...</div>
<div class="display-flex align-items-baseline">...</div>
<div class="display-flex align-items-stretch">...</div>
`}</code></pre>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`center
start
end
baseline
flex-start
flex-end
unset
`}</code></pre>
    <p>{`And all supports the suffix breakpoint like `}<inlineCode parentName="p">{`sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`xl`}</inlineCode>{`, `}<inlineCode parentName="p">{`xxl`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`align-items-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`align-items-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "align-self"
    }}>{`Align Self`}</h2>
    <div className='grid-box display-flex align-items-center mb-3' style={{
      "height": "100px"
    }}>
  <div className='background-gray-light black p-2 align-self-start mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1 align-self-flex-end'>Item</div>
  <div className='background-gray-light black p-2 mx-1 align-self-stretch'>Item</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="display-flex align-items-center">
  <div class='align-self-flex-start'>Item</div>
  <div>Item</div>
  <div class='align-self-flex-end'>Item</div>
  <div class='align-self-stretch'>Item</div>
</div>
`}</code></pre>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`center
start
end
baseline
flex-start
flex-end
unset
`}</code></pre>
    <p>{`And all supports the suffix breakpoint like `}<inlineCode parentName="p">{`sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`xl`}</inlineCode>{`, `}<inlineCode parentName="p">{`xxl`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`align-self-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`align-self-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "justify-content"
    }}>{`Justify Content`}</h2>
    <div className='grid-box display-flex justify-content-center mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex align-items-flex-start mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex justify-content-flex-end mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex justify-content-space-between mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex justify-content-space-evenly mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <div className='grid-box display-flex justify-content-space-around mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="display-flex justify-content-center">...</div>
<div class="display-flex justify-content-flex-start">...</div>
<div class="display-flex justify-content-flex-end">...</div>
<div class="display-flex justify-content-space-between">...</div>
<div class="display-flex justify-content-space-evenly">...</div>
<div class="display-flex justify-content-space-around">...</div>
`}</code></pre>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`center
start
end
flex-start
flex-end
space-between
space-evenly
space-around
left
right
unset
`}</code></pre>
    <p>{`And all supports the suffix breakpoint like `}<inlineCode parentName="p">{`sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`xl`}</inlineCode>{`, `}<inlineCode parentName="p">{`xxl`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`justify-content-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`justify-content-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "justify-self"
    }}>{`Justify Self`}</h2>
    <div className='grid-box display-flex justify-content-space-around mb-3' style={{
      "height": "auto"
    }}>
  <div className='background-gray-light black p-2 mx-1 justify-self-flex-start'>Item</div>
  <div className='background-gray-light black p-2 mx-1'>Item</div>
  <div className='background-gray-light black p-2 mx-1 justify-self-flex-end'>Item</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="display-flex justify-content-center">
  <div class='justify-self-flex-start'>Item</div>
  <div>Item</div>
  <div class='justify-self-flex-en'>Item</div>
</div>
`}</code></pre>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`center
start
end
flex-start
flex-end
space-between
space-evenly
space-around
left
right
unset
`}</code></pre>
    <p>{`And all supports the suffix breakpoint like `}<inlineCode parentName="p">{`sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`xl`}</inlineCode>{`, `}<inlineCode parentName="p">{`xxl`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`justify-self-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`justify-slef-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      